// Module Import
//---------------------------------
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TypographyAtom from '../typography/typography'
import { brandGrey, brandOrange, brandSoftGrey } from '../../../assets/consts/color'
import checkIcon from '../../../assets/images/icon_check.svg'
import { primaryFont, secondaryFont } from '../../../assets/consts/fonts'

// Component Import
//---------------------------------

// Styled Component Declarations
//---------------------------------
const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  &[aria-required='true']::after {
    position: absolute;
    top: 0;
    left: 16px;
    content: '*';
    color: red;
  }
`

const FieldLabel = styled.label`
  color: ${brandSoftGrey};
  font-family: 'F37 Bolton';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 87.5% */
  display: ${({ show }) => (show ? 'flex' : 'none')};
  gap: 24px;
  align-items: center;
  width: 100%;

  p {
    font-family: ${primaryFont};
  }
`

const CheckBoxTrigger = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border: 1px solid ${brandGrey};
  cursor: pointer;
  /* border-radius: 4px; */
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:hover {
    border-color: ${brandOrange};
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${brandOrange};
    /* background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; */
    opacity: 0;
    transform: translate(-50%, -50%);
    transition:
      opacity 0.25s ease-in-out,
      color 0.25s ease-in-out;
  }
`

export const InputField = styled.input`
  display: flex;
  padding: 16px;
  background-color: transparent;
  align-self: stretch;
  border: 0;
  border-bottom: 2px solid ${brandSoftGrey};
  transition: all 0.25s;
  color: ${brandSoftGrey};

  font-family: ${secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 20px */
  width: 100%;

  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
  }

  &[type='checkbox'] {
    display: none;
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid ${brandOrange};
  }

  &:hover {
    border-bottom: 2px solid ${brandOrange};
  }

  &:checked ~ label > ${CheckBoxTrigger} {
    /* background-color: ${brandOrange}; */
    border-color: ${brandOrange};

    &::after {
      opacity: 1;
    }
  }
`

// Component Export
//---------------------------------
export default function Field({
  labelText,
  labelFor,
  placeholderText,
  fieldType,
  fieldId,
  fieldName,
  required,
  fullWidth,
  ...prop
}) {
  return (
    <FieldWrapper aria-required={required} fullWidth={fullWidth}>
      <InputField
        type={fieldType}
        id={fieldId}
        name={fieldName}
        placeholder={placeholderText}
        {...prop}
      />
      <FieldLabel htmlFor={labelFor} show={fieldType === 'checkbox'}>
        {fieldType === 'checkbox' && <CheckBoxTrigger />}
        <TypographyAtom type="P" copy={labelText} weight="Regular" />
      </FieldLabel>
    </FieldWrapper>
  )
}

Field.propTypes = {
  labelText: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}
